<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="key_name"
                                    label="Key Name"
                                    disabled
                                    required
                                ></v-text-field>

                                <v-text-field
                                    v-model="value"
                                    label="Giá trị"
                                    required
                                ></v-text-field>
                            </div>

                            
                            <div class="col-md-12">
                                <v-btn class="ma-2 float-right" :loading="loading" color="info" @click="update_setting">Sửa</v-btn>
                                <v-btn class="ma-2 float-right" :loading="loading" @click="cancel">Huỷ</v-btn>
                            </div>
                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Sửa cài đặt'
    },
    data() {
        return {
            valid: true,
            id: null,
            key_name: "",
            value: "",
            loading: false,
            name_rules: [],
        };
    },
    watch: {
        setting(val) {
            this.id = val.Id;
            this.key_name = val.Key_name;
            this.value = val.Value_config;
        }
    },
    computed: {
        setting() {
            return this.$store.getters['settings/get_item'](this.$route.params.id);
        },
    },
    components: {

    },
    methods: {
        update_setting() {
            this.loading = true;
            var payload = {
                Id: this.id,
                Key_name: this.key_name,
                Value_config: this.value,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('settings/update_setting', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'settings-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.push({ name: 'settings-index' });
        },
    },
    mounted() {
        // this.$store.dispatch(SET_BREADCRUMB, [ { title: "Từ khoá", route: "index" }, { title: "Sửa từ khoá" } ]);
        this.$store.dispatch('settings/get_all');
    }
};
</script>



